<!--
Event Availability Card Staff Instructions Dialog

This dialog is used in the Event Availability Card component, and displays the staff instructions for the event.
It is viewable by all stewards.

Props:
- eventData: Object - The event data object.
-->
<template>
	<div class="appGrey rounded-lg pa-4">

		<app-text>{{ eventData.eventStaffInstructions }}</app-text>

		<v-divider class="mt-4"/>

		<app-btn @click.native="closeDialog"
				 :block="true"
				 class="mt-4"
				 label="Close"/>

	</div>
</template>

<script>
export default {

	name: "EventAvailabilityCardStaffInstructionsDialog",

	props: ['eventData'],

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventAvailabilityCardStaffInstructionsDialog')
		},

	},

}
</script>

<style scoped>

</style>
