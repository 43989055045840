<template>
	<div class="appGrey rounded-lg pa-4">

		{{ computedInit }}

		<app-text size="normal-bold">Team Event Notes</app-text>

		<app-text class="mt-4">
			You can add event notes for <b>{{ teamName }}</b> which can be viewed by the team's Supervisor and Deputy.
		</app-text>

		<app-form-field form-type="textArea"
						class="mt-4"
						label="Notes"
						placeholder="Add an event note for this team..."
						v-model="newTeamNotes"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="handleCancelButton"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Save-->
			<app-btn @click.native="handleSaveButton"
					 color="green"
					 icon="save"
					 label="Save"/>

		</div>

	</div>
</template>

<script>

export default {

	name: "EventTeamNotesDialog",

	props: ['teamName', 'teamNotes'],

	data: () => ({
		newTeamNotes: '',
	}),

	computed: {

		computedInit() {
			const t = this
			t.newTeamNotes = t.$props.teamNotes
		}

	},

	methods: {

		handleCancelButton() {
			const t = this

			t.$emit('emitCloseDialog')
		},

		async handleSaveButton() {
			const t = this
			const TEAM_NOTES = t.newTeamNotes

			t.$emit('emitSaveEventTeamNotes', TEAM_NOTES)
		}

	},

}
</script>

<style scoped>

</style>
