import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/router'
import vuetify from '@/vuetify'

Vue.config.productionTip = false

/**
 * Animations
 */
import '../node_modules/animate.css/animate.min.css';

/**
 * Axios
 */
import axios from 'axios';
Vue.prototype.$axios = axios

/**
 * Firebase
 */
// Firebase
const firebase = require("@/firebase/firebase-config.js");
Vue.prototype.$firebase = firebase;
// Firebase Auth Mixin
import firebaseAuthMixin from '@/firebase/firebase_auth_mixin'
Vue.use(firebaseAuthMixin)

/**
 * Icons
 */
import "@/assets/icons/css/styles.min.css";

/**
 * Main Mixin
 */
import mixin from '@/mixin.js';
Vue.use(mixin);

/**
 * QR Code Reader
 */
// import VueQrcodeReader from "vue-qrcode-reader";
// Vue.use(VueQrcodeReader)

/**
 * Redis
 */
import redisMixin from "@/redis/redis-mixin";
Vue.use(redisMixin)

/**
 * Service Worker
 */
import './registerServiceWorker'
import sw_mixin from "@/sw_mixin";
Vue.use(sw_mixin)

/**
 * Shared State
 */
import sharedState from "@/sharedState";
Vue.prototype.$sharedState = sharedState

/**
 * Vue2 Editor (Rich text editor)
 */
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor)

/**
 * Global Components
 */
import AppBtn from "@/components/app/AppBtn";
Vue.component('AppBtn', AppBtn)

import AppDialog from "@/components/app/AppDialog";
Vue.component('AppDialog', AppDialog)

import AppFormField from "@/components/app/AppFormField";
Vue.component('AppFormField', AppFormField)

import AppIcon from "@/components/app/AppIcon";
Vue.component('AppIcon', AppIcon)

import AppText from "@/components/app/AppText";
Vue.component('AppText', AppText)

import Avatar from "@/components/Avatar";
Vue.component('Avatar', Avatar)

import CloseIcon from "@/components/CloseIcon";
Vue.component('CloseIcon', CloseIcon)

import DeleteIcon from "@/components/DeleteIcon";
Vue.component('DeleteIcon', DeleteIcon)

import DatePicker from "@/components/DatePicker";
Vue.component('DatePicker', DatePicker)

import EditIcon from "@/components/EditIcon";
Vue.component('EditIcon', EditIcon)

import ErrorMessage from "@/components/ErrorMessage";
Vue.component('ErrorMessage', ErrorMessage)

import FilterPanel from "@/components/FilterPanel";
Vue.component('FilterPanel', FilterPanel)

import FormSectionTitle from "@/components/FormSectionTitle";
Vue.component('FormSectionTitle', FormSectionTitle)

import ImageUpload from "@/components/ImageUpload";
Vue.component('ImageUpload', ImageUpload)

import MoreActionsMenu from "@/components/MoreActionsMenu";
Vue.component('MoreActionsMenu', MoreActionsMenu)

import PageBreakTitle from "@/components/PageBreakTitle";
Vue.component('PageBreakTitle', PageBreakTitle)

import PageTitle from "@/components/PageTitle";
Vue.component('PageTitle', PageTitle)

import PageLoadingAnimation from "@/components/PageLoadingAnimation";
Vue.component('PageLoadingAnimation', PageLoadingAnimation)

import PageStartInformation from "@/components/PageStartInformation";
Vue.component('PageStartInformation', PageStartInformation)

import TimePicker from "@/components/TimePicker";
Vue.component('TimePicker', TimePicker)

import TimePicker2 from "@/components/TimePicker2";
Vue.component('TimePicker2', TimePicker2)

let app
firebase.auth.onAuthStateChanged(currentUserAuth => {
    if (!app) {
        app = new Vue({
            router,
            vuetify,
            render: h => h(App)
        }).$mount('#app')
    }
})
