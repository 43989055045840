<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructions-->
		<app-text class="mb-4">
			Complete the form to mark
			<strong>{{ selectedItem.userData.userName }}</strong>
			as arriving late.
		</app-text>

		<!--Instructions - Already Late-->
		<app-text v-if="selectedItem[`${viewType}Data`][`${viewType}LateArrivalTime`] !== '0'" class="mb-4" color="red">
			<strong>{{ selectedItem.userData.userName }}</strong>
			was already marked late at
			<strong>{{ selectedItem[`${viewType}Data`][`${viewType}LateArrivalTime`] }}</strong>,
			but you can update the details if you wish.
		</app-text>

		<!--Time Selection-->
		<time-picker2 @emitTime="handleTimeSelection($event, 'lateArrivalTime')"
					  :error="errors.lateArrivalTime"
					  :error-message="errors.lateArrivalTimeErrorMessage"
					  label="Late Arrival time"
					  :time="lateArrivalTime"/>

		<!--Notes-->
		<app-form-field form-type="textArea"
						class="mt-4"
						:error="errors.lateArrivalNotes"
						:error-message="errors.lateArrivalNotesErrorMessage"
						label="Late Arrival Notes"
						v-model.trim="lateArrivalNotes"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Yes-->
			<app-btn @click.native="handleYesButton"
					 color="green"
					 icon="success"
					 label="Late"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventControlPanelLateArrivalDialog",

	props: ['selectedItem', 'viewType'],

	data: () => ({
		errors: {
			lateArrivalTime: false,
			lateArrivalTimeErrorMessage: '',
			lateArrivalNotes: false,
			lateArrivalNotesErrorMessage: '',
		},
		lateArrivalNotes: '',
		lateArrivalTime: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * Initialise the component.
		 */
		computedInit() {
			const t = this
			const VIEW_TYPE = t.$props.viewType
			const FORM_DATA = t.$props.selectedItem[`${VIEW_TYPE}Data`]
			const LATE_ARRIVAL_TIME = FORM_DATA[`${VIEW_TYPE}LateArrivalTime`]
			const LATE_ARRIVAL_NOTES = FORM_DATA[`${VIEW_TYPE}LateArrivalNotes`]

			t.lateArrivalTime = LATE_ARRIVAL_TIME === '0' ? '' : LATE_ARRIVAL_TIME
			t.lateArrivalNotes = LATE_ARRIVAL_NOTES || ''
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog box.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventControlPanelLateArrivalDialog')
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 * Validate the time format, so if the min or max is set, the time must be within that range.
		 *
		 * @param time {string} the time to set
		 * @param min {string} the minimum time
		 * @param max {string} the maximum time
		 * @param field {string} the form field to set
		 */
		handleTimeSelection({time, min, max}, field) {
			const t = this

			// Clear any errors
			t.errors[field] = false
			t.errors[field + 'ErrorMessage'] = ''

			// Validate the time format
			if (min && time < min) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be after ' + min
				return
			}

			// Validate the time format
			if (max && time > max) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be before ' + max
				return
			}

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Handle Yes Button
		 *
		 * Emit back to the parent to update the time.
		 */
		handleYesButton() {
			const t = this

			// Only continue if the form is valid
			if (!t.validateForm()) return

			t.$emit('emitEventControlPanelLateArrival', {
				lateArrivalTime: t.lateArrivalTime,
				lateArrivalNotes: t.lateArrivalNotes
			})
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			// Clear any existing errors
			t.clearErrors()

			// Check the time is valid
			if (!t.lateArrivalTime) {
				t.errors.lateArrivalTime = true
				t.errors.lateArrivalTimeErrorMessage = 'Time is required'
			}

			// Check the notes are valid
			if (!t.lateArrivalNotes) {
				t.errors.lateArrivalNotes = true
				t.errors.lateArrivalNotesErrorMessage = 'Notes are required'
			}

			// Return true if there are no errors
			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
