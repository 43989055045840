const mixin = {

	methods: {

		/**
		 * Crud Functions ----------------------------------------------------------------------------------------------
		 */

		/**
		 * Create
		 *
		 * Create a new key using the entity name and the data.
		 * Add the 'Created' metadata before saving.
		 *
		 * @param entity name of entity
		 * @param formData data to save
		 * @returns {Promise<AxiosResponse<any> | void>} server response
		 */
		async MIX_redis_create(entity, formData) {
			const t = this

			// There will be no current user data when creating a user
			const CURRENT_USER_DATA = entity !== 'user' && t.MIX_getCurrentUser()

			formData.createdDateTime = new Date().getTime()
			formData.createdUserId = CURRENT_USER_DATA?.entityId || ''
			formData.createdUserName = CURRENT_USER_DATA?.userName || ''

			return t.$axios.put(`${process.env.VUE_APP_SERVER}/${entity}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error creating: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Delete
		 *
		 * Delete a key by its entityId.
		 * This does not delete the key, but marks it as deleted.
		 *
		 * @param entity name of entity
		 * @param id ID of key
		 * @param formData data to update
		 * @returns {Promise<AxiosResponse<any> | void>} server response
		 */
		async MIX_redis_delete(entity, id, formData) {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			formData.isDeleted = true
			formData.deletedDateTime = new Date().getTime()
			formData.deletedUserId = CURRENT_USER_DATA.entityId
			formData.deletedUserName = CURRENT_USER_DATA.userName

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/${entity}/delete/${id}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error deleting: `, error)
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Destroy
		 *
		 * Destroy (delete) a key using the entity ID.
		 *
		 * @param entity the name of the collection to delete from
		 * @param id
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_destroy(entity, id) {
			const t = this

			return t.$axios.delete(`${process.env.VUE_APP_SERVER}/${entity}/${id}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					console.error(`Error destroying: `, error)
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get By ID
		 *
		 * Get a single key by ID for the given entity.
		 *
		 * @param entity name of entity
		 * @param id ID of key
		 * @returns {Promise<AxiosResponse<any> | void>} array of Organisations
		 */
		async MIX_redis_getById(entity, id) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/${entity}/${id}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error fetching: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get All
		 *
		 * Get all the collection data for the given entity.
		 *
		 * @param entity name of entity
		 * @returns {Promise<AxiosResponse<any> | void>} array of entity object
		 */
		async MIX_redis_getAll(entity) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/${entity}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Entities By IDs
		 *
		 * Get all the collection data for the given entity by an array of IDs.
		 *
		 * @param entity {string} the entity name
		 * @param ids {array} an array of IDs
		 */
		async MIX_redis_getEntitiesByIds(entity, ids) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/${entity}/get/entitiesByIds`, {ids})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error fetching ${entity}: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update
		 *
		 * Update a key by its entityId.
		 *
		 * @param id ID of key
		 * @param entity name of entity
		 * @param formData data to update
		 * @returns {Promise<AxiosResponse<any> | void>} server response
		 */
		async MIX_redis_update(entity, id, formData) {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			formData.modifiedDateTime = new Date().getTime()
			formData.modifiedUserId = CURRENT_USER_DATA.entityId
			formData.modifiedUserName = CURRENT_USER_DATA.userName

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/${entity}/${id}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error updating: `, error)
					return {
						data: {},
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Entities By Where And Fields
		 *
		 * Get all the collection data for the given entity, by a where clause and only return the required fields.
		 *
		 * @param entity {string} the entity name
		 * @param whereData {array} an array of where objects {whereKey: string, whereValue: string}
		 * @param fieldsData {array} an array of fields to return as strings
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getEntitiesByWhereAndFields(entity, whereData, fieldsData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/appFunctions/get/entitiesByWhereAndFields`, {
				entity,
				whereData,
				fieldsData
			})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error fetching ${entity}: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Other Functions ---------------------------------------------------------------------------------------------
		 */

		MIX_redis_adminUpdateAccreditationPasses(passesData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationPass/update/adminUpdateAccreditationPasses`, passesData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error updating Accreditation Passes: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		MIX_redis_adminUpdateMediaAccreditationPasses(passesData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/mediaAccreditationPass/update/adminUpdateAccreditationPasses`, passesData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error updating Accreditation Passes: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Bulk Create Users
		 *
		 * Bulk create Users.
		 * @param formData {object} - the form data containing CSV and ZIP files, and the user config data
		 */
		async MIX_redis_bulkCreateUsers(formData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/user/create/bulkCreateUsers`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error bulk creating Users: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_bulkSignInUsersToEvent(userIds, eventId, signInDateTime) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/event/update/bulkSignInUsersToEvent`, {
				userIds,
				eventId,
				signInDateTime
			})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error bulk signing in Users to Event: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Check For Currently Active Swapp Tasks List
		 *
		 * Check for a currently active SWAPP Tasks List.
		 *
		 * @param swappTaskListQrId {string} - the SWAPP Tasks List QR Code ID
		 */
		async MIX_redis_checkForCurrentlyActiveSwappTasksList(swappTaskListQrId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/swappTasksList/get/checkForCurrentlyActiveSwappTasksList/${swappTaskListQrId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error checking for Currently Active Swapp Tasks List: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Check If Reserved Spaces In Use
		 *
		 * Check if a Car PArk's Reserved Spaces are in use for a given Event.
		 * @param eventId {string} the Event ID
		 * @param carParkId {string} the Car Park ID
		 */
		async MIX_redis_checkIfReservedSpacesInUse(eventId, carParkId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/parkingSpace/get/checkIfReservedSpacesInUse/${carParkId}/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error checking if Reserved Spaces in use: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Create Accreditation Passes
		 *
		 * Create the Accreditation Passes for the Event by Organisation.
		 * As well as the Accreditation data, the formData also includes an array of users to create the passes for.
		 *
		 * @param formData {object} the Accreditation Pass data
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_createAccreditationPasses(formData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationPass/create/accreditationPasses`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error creating Accreditation Passes: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Create Organisation User
		 *
		 * Create a new Organisation User (either Manager or User).
		 *
		 * The form data for this should include:
		 *  - userName
		 * 	- userEmail
		 * 	- userPosition
		 * 	- userRole
		 * 	- Manager
		 *
		 * @param formData {object} the User's form data
		 * @param organisationId {string} the Organisation ID
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_createAccreditationUser(formData, organisationId) {
			const t = this

			return t.$axios
				.put(`${process.env.VUE_APP_SERVER}/organisation/create/organisationUser/${organisationId}`, formData)
				.then((response) => {
					return {
						data: response.data,
						hasErrors: false,
						error: {},
					}
				})
				.catch((error) => {
					console.error(`Error creating Organisation Manager: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error,
					}
				})
		},

		/**
		 * Create Event Teams
		 *
		 * Send an array of Teams off to the server to create EventTeams.
		 *
		 * @param formData - Event Data object, and an array of Teams
		 * @returns {Promise<AxiosResponse<any> | void>} success message, or error object
		 */
		async MIX_redis_createEventTeams(formData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/eventTeam/create/eventTeams`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error updating Event Teams: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Create Accreditation Passes
		 *
		 * Create the Accreditation Passes for the Event by Organisation.
		 * As well as the Accreditation data, the formData also includes an array of users to create the passes for.
		 *
		 * @param formData {object} the Accreditation Pass data
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_createMediaAccreditationPasses(formData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/mediaAccreditationPass/create/accreditationPasses`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error creating Accreditation Passes: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Create Registered User
		 *
		 * Create a new Registered User.
		 *
		 * @param formData {object} - the form data
		 */
		async MIX_redis_createRegisteredUser(formData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/user/create/registeredUser`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error creating Registered User: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Delete Event
		 *
		 * Delete the Event from the database by its ID.
		 */
		async MIX_redis_deleteEvent(eventId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/event/delete/deleteEvent/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error deleting Event: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Delete Event Team From Event
		 *
		 * Delete the EventTeam from the Event.
		 * Also delete any EventUser documents if the Event has been published.
		 *
		 * @returns {Promise<AxiosResponse<any> | void>} success message, or error object
		 */
		async MIX_redis_deleteEventTeamFromEvent(eventTeamId) {
			const t = this

			return t.$axios.delete(`${process.env.VUE_APP_SERVER}/eventTeam/delete/removeEventTeamFromEvent/${eventTeamId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error removing Event Teams: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get All Swapp Tasks Data By Qr Code
		 *
		 * Get all the SWAPP Tasks data by the QR Code.
		 *
		 * @param swappTaskListQrId {string} - the SWAPP Tasks List QR Code ID
		 */
		async MIX_redis_getAllSwappTasksDataByQrCode(swappTaskListQrId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/swappTasksList/get/allSwappTasksDataByQrCode/${swappTaskListQrId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting All SWAPP Tasks Data for QR Code ID: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Event Day Dashboard Data By Event
		 *
		 *
		 *
		 * @param eventId - the event to fetch the data for
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getEventDayDashboardDataByEvent(eventId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/eventTeam/get/eventDayDashboardDataByEvent/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Event Parking Data
		 *
		 * Get the Parking Spaces by Event ID.
		 *
		 * @param eventId {string} the Event ID
		 */
		async MIX_redis_getEventParkingData(eventId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/parkingSpace/get/getEventParkingData/${eventId}`,)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Event Parking Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get All EventTeams by Event
		 *
		 * Return all EventTeams for the given Event
		 *
		 * @param eventId - the event to fetch the EventTeams for
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getEventTeamsByEvent(eventId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/eventTeam/get/eventTeamsByEvent/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})

		},

		/**
		 * Get EventTeams Figures By Event
		 *
		 * Calculate the figures requires for the EventTeams table, for the current Event.
		 *
		 * @param eventId - The ID of the Event to get the figures for
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getEventTeamsFiguresByEvent(eventId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/eventTeam/get/eventTeamsFiguresByEvent/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_getEventTeamsForLiveEventsByTeamId(teamId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/eventTeam/get/eventTeamsForLiveEventsByTeamId/${teamId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting EventTeams: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get EventUsers By Event And Team
		 *
		 * Return EventUsers by the given Event and Team.
		 *
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getEventUsersByEventAndTeam(eventId, teamId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/eventUser/get/eventUsersByEventAndTeam/${eventId}/${teamId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})

		},

		/**
		 * Get Event Users By User And Live Events
		 *
		 * Get all the EventUser documents for Live Events, for the given User.
		 *
		 * @param userId - the user ID to fetch the documents for
		 * @returns {Promise<AxiosResponse<any> | void>} array of entity object
		 */
		async MIX_redis_getEventUsersByUserAndLiveEvents(userId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/eventUser/get/eventUsersByUserAndLiveEvents/${userId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting EventUsers: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Event Users Where
		 */
		async MIX_redis_getEventUsersWhere(whereData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/eventUser/get/eventUsersWhere`, whereData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Event Users Where: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Events Where
		 *
		 * Get all the Events by a where clause.
		 *
		 * @param {{whereKey: string, whereValue: string}} whereData - an object containing the key and value for the search
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getEventsWhere(whereData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/event/get/eventsWhere`, whereData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Events: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Files Where
		 *
		 * Get all files by a where clause.
		 *
		 * @param whereClause {object} the where clause
		 */
		async MIX_redis_getFilesWhere(whereClause) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/file/get/filesWhere`, whereClause)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Files Where: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})

		},

		/**
		 * Get Full Accreditation Events Data
		 *
		 * Get all the full Accreditation Events data.
		 *  - Events Data
		 *  - Accreditation Events Data
		 */
		async MIX_redis_getFullAccreditationEventsData() {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationEvent/get/fullAccreditationEventsData/`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all Accreditation Events data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Accreditation Passes Data By Event And Organisation
		 *
		 * Get all the data required to display the Accreditation Passes page.
		 * This includes:
		 *  - An array of users for the Organisation, with passes
		 *  - An array of users for the Organisation, without passes
		 *
		 * @param eventId {string} the Event ID
		 * @param organisationId {string} the Organisation ID
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getFullAccreditationPassesDataByEventAndOrganisation(eventId, organisationId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/accreditationPass/get/fullAccreditationPassesDataByEventAndOrganisation/${eventId}/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Accreditation Passes: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Accreditations Data
		 *
		 * Get all the data required to display the Accreditations page.
		 * This includes:
		 *  - The Organisation data
		 *  - The Users' of the Organisation data
		 *
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getFullAccreditationsData() {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/accreditationOrganisation/get/fullAccreditationsData`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Accreditations Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Event Data By Event ID
		 *
		 * Get all the data required to display the Event page.
		 *
		 * @param eventId {string} the Event ID
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getFullEventDataByEventId(eventId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/event/get/fullEventDataByEventId/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Events Data by Event ID: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Live Events Data
		 *
		 * Get all the data required to display the Live Events data to the Steward.
		 *
		 * @param userId {string} the User ID
		 */
		async MIX_redis_getFullLiveEventsData(userId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/event/get/fullLiveEventsData/${userId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Live Events Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Accreditation Events Data
		 *
		 * Get all the full Accreditation Events data.
		 *  - Events Data
		 *  - Accreditation Events Data
		 */
		async MIX_redis_getFullMediaAccreditationEventsData() {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/mediaAccreditationEvent/get/fullAccreditationEventsData/`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all Accreditation Events data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Accreditation Passes Data By Event And Organisation
		 *
		 * Get all the data required to display the Accreditation Passes page.
		 * This includes:
		 *  - An array of users for the Organisation, with passes
		 *  - An array of users for the Organisation, without passes
		 *
		 * @param eventId {string} the Event ID
		 * @param organisationId {string} the Organisation ID
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getFullMediaAccreditationPassesDataByEventAndOrganisation(eventId, organisationId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/accreditationPass/get/fullAccreditationPassesDataByEventAndOrganisation/${eventId}/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Accreditation Passes: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Organisation Single Accreditation Data
		 *
		 * Get all the full single Accreditation data.
		 */
		async MIX_redis_getFullOrganisationSingleAccreditationData(eventId, organisationId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationEvent/get/fullOrganisationSingleAccreditationData/${eventId}/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Organisation Full Single Accreditation data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Organisation Single Accreditation Data
		 *
		 * Get all the full single Accreditation data.
		 */
		async MIX_redis_getFullOrganisationSingleMediaAccreditationData(eventId, organisationId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/mediaAccreditationEvent/get/fullOrganisationSingleAccreditationData/${eventId}/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Organisation Full Single Accreditation data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Organisations Data
		 *
		 * Get all the data required to display the Organisations page.
		 */
		async MIX_redis_getFullOrganisationsData() {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/organisation/get/fullOrganisationsData`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Organisations Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Parking Spaces
		 */
		async MIX_redis_getFullParkingSpaces() {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/parkingSpace/get/fullParkingSpaces`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Parking Spaces: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Pending Events Data
		 *
		 * Get all the data required to display the Pending Events data to the Steward.
		 *
		 * @param userId {string} the User ID
		 */
		async MIX_redis_getFullPendingEventsData(userId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/event/get/fullPendingEventsData/${userId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Pending Events Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Single Accreditation Event Data
		 *
		 * Get all the full single Accreditation Event data.
		 * 	- Event Data
		 * 	- Accreditation Event Data
		 * 	- Passes Data
		 * 	-- Organisation Data
		 * 	-- Pass Data
		 * 	-- User Data
		 *
		 * @param eventId {string} - the Event ID
		 */
		async MIX_redis_getFullSingleAccreditationEventData(eventId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationEvent/get/fullSingleAccreditationEventData/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Single Accreditation Event data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Single Accreditation Event Data
		 *
		 * Get all the full single Accreditation Event data.
		 * 	- Event Data
		 * 	- Accreditation Event Data
		 * 	- Passes Data
		 * 	-- Organisation Data
		 * 	-- Pass Data
		 * 	-- User Data
		 *
		 * @param eventId {string} - the Event ID
		 */
		async MIX_redis_getFullSingleMediaAccreditationEventData(eventId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/mediaAccreditationEvent/get/fullSingleAccreditationEventData/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Single Accreditation Event data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Single Organisation Data
		 *
		 * Get all the data for a Full Single Organisation.
		 *  - Organisation Data
		 *  - Users Data
		 *  -- User Data
		 *  -- File Data
		 */
		async MIX_redis_getFullSingleOrganisationData(organisationId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/organisation/get/fullSingleOrganisationData/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Organisation Full Single Organisation data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full User Data
		 *
		 * Get all the data required to display a single User.
		 */
		async MIX_redis_getFullUserData(userFbId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/user/get/fullUserData/${userFbId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full User Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Users Data
		 *
		 * Get all the data required to display the Users data.
		 */
		async MIX_redis_getFullUsersData() {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/user/get/fullUsersData`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Full Users Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Full Working SWAPP Tasks Data
		 *
		 * Get all the data required to display the working SWAPP Tasks.
		 * This includes:
		 * - The SWAPP Tasks Lists data
		 * - The SWAPP Tasks data
		 *
		 * Note: This is working data, which doesn't include Template or Library data.
		 */
		async MIX_redis_getFullWorkingSwappTasksData() {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/swappTasksList/get/fullWorkingSwappTasksData`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting Full SWAPP Tasks Lists Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Latest Updates Cards Data
		 *
		 * Get the data for the Home page's Latest Updates cards.
		 */
		async MIX_redis_getLatestUpdatesCardsData() {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/appFunctions/getLatestUpdatesCardsData`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting Latest Updates Cards Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get New Swapp Tasks List From Qr Code
		 *
		 * Get the new SWAPP Tasks List from the QR Code.
		 */
		async MIX_redis_getNewSwappTasksListFromQrCode(qrCode) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/swappTasksList/get/newSwappTasksListFromQrCode/${qrCode}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting New SWAPP Tasks List From Qr Code: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Parking Dashboard Data by Event ID
		 *
		 * Get the Parking Dashboard Data by Event ID.
		 * @param eventId {string} the Event ID
		 */
		async MIX_redis_getParkingDashboardDataByEventId(eventId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/parkingSpace/get/getParkingDashboardDataByEventId/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Parking Dashboard Data by Event ID: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Parking Qr Code Data
		 *
		 * Get the Parking Qr Code Data by the QR Code.
		 *
		 * @param qrData {string} the QR Code
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getParkingQrCodeData(qrData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/parkingSpace/get/getParkingQrCodeData`, qrData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Parking QR Code Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Payroll Data
		 *
		 * Get the Payroll Data by the Event ID.
		 *
		 * @param eventId {string} the Event ID
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getPayrollData(eventId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/event/get/payrollData/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting Payroll Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Swapp Tasks List Tasks
		 *
		 * Get all the Tasks data for the given List ID.
		 */
		async MIX_redis_getSwappTasksListTasks(swappTasksListId, getTasksFrom) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/swappTasksTask/get/swappTasksListTasks`, {
				swappTasksListId,
				getTasksFrom
			})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting SWAPP Tasks List Tasks: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get SWAPP Tasks List Where
		 *
		 * Get the SWAPP Tasks List by a where clause.
		 */
		async MIX_redis_getSwappTasksListWhere(whereData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/swappTasksList/get/swappTasksListsWhere`, whereData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting SWAPP Tasks List Where: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get SWAPP Tasks (Tasks) Where
		 *
		 * Get the SWAPP Tasks (Tasks) by a where clause.
		 */
		async MIX_redis_getSwappTasksTasksWhere(whereData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/swappTasksTask/get/swappTasksTasksWhere`, whereData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting SWAPP Tasks Tasks Where: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Get Teams Where
		 *
		 * Return all Teams by a where clause.
		 *
		 * @param {{whereKey: string, whereValue: string}} whereData - an object containing the key and value for the search
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getTeamsWhere(whereData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/team/get/teamsWhere`, whereData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})

		},

		/**
		 * Get ToDo Tasks Where
		 *
		 * Get all the ToDo Tasks by a where clause.
		 *
		 * @param {{whereKey: string, whereValue: string}} whereData - an object containing the key and value for the search
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getToDoTasksWhere(whereData) {
			const t = this

			return t.$axios
				.post(`${process.env.VUE_APP_SERVER}/toDoTask/get/toDoTasksWhere`, whereData)
				.then((response) => {
					return {
						data: response.data,
						hasErrors: false,
						error: {},
					}
				})
				.catch((error) => {
					console.error(`Error getting ToDo Tasks: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error,
					}
				})
		},

		/**
		 * Get Users Where
		 *
		 * Return all Users by a where clause.
		 *
		 * @param {[{whereKey: string, whereValue: string}]} whereData {object} the Where key and value for the search
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_getUsersWhere(whereData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/user/get/usersWhere`, whereData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting User where: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})

		},

		/**
		 * Publish Event
		 *
		 * Call to publish the Event by its ID.
		 * This:
		 *   - Creates all the EventUser documents for the Users of the Teams
		 *   - Sets the Event status to Live
		 *   - Sets the Event isPublished flag to true
		 *
		 * @param eventId {string} The ID of the Event to publish
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_publishEvent(eventId) {
			const t = this

			return t.$axios.get(`${process.env.VUE_APP_SERVER}/event/create/publishEvent/${eventId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Send Parking QR Code
		 *
		 * Send the QR Code Email to the User.
		 *
		 * @param parkingData {array} - an array of parking data objects, each containing the ParkingSpace ID and CarParkColour.
		 */
		async MIX_redis_sendParkingQrCode(parkingData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/parkingSpace/send/sendQrCodeEmail`, parkingData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error sending QR Code Email: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Send Swapp Task Issue Email
		 *
		 * Send the SWAPP Task Issue Email.
		 * @param swappTaskData {object} - the SWAPP Task Issue data
		 */
		async MIX_redis_sendSwappTaskIssueEmail(swappTaskData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/swappTasksTask/send/swappTaskIssueEmail`, {swappTaskData})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error sending SWAPP Task Issue Email: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update Accreditation Event Organisation
		 *
		 * Update the Accreditation Event with the Organisation's ID, so it's registered for the event.
		 *
		 * @param accreditationEventId {string} - the Accreditation Event ID
		 * @param organisationId {string} - the Organisation ID
		 */
		async MIX_redis_updateAccreditationEventOrganisation(accreditationEventId, organisationId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationEvent/update/accreditationEventOrganisation/${accreditationEventId}/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error updating Accreditation Event Organisation: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update Accreditation Pass Print Statuses
		 *
		 * Update the print statuses of the selected Accreditation Passes.
		 * @param payload {object} - the passIds, and the isPrinted flag
		 */
		MIX_redis_updateAccreditationPassPrintStatuses(payload) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationPass/update/updateAccreditationPassPrintStatuses`, payload)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error updating Accreditation Pass Print Statuses: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update All Accreditation Pass Print Status By Event And Organisation
		 *
		 * Update the print status of all the Accreditation Passes for the Event by Organisation.
		 *
		 * @param eventId {string} the Event ID
		 * @param organisationId {string} the Organisation ID
		 * @param isPrinted {boolean} the print status
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_updateAllAccreditationPassesPrintStatusByEventAndOrganisation(eventId, organisationId, isPrinted) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationPass/update/updateAllAccreditationPassesPrintStatusByEventAndOrganisation/${eventId}/${organisationId}`, isPrinted)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error updating All Accreditation Passes print status: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update All Accreditation Passes Status By Event And Organisation
		 *
		 * Update the status of all the Accreditation Passes for the Event by Organisation.
		 *
		 * @param eventId {string} the Event ID
		 * @param organisationId {string} the Organisation ID
		 * @param newStatus {string} the new status
		 * @returns {Promise<{hasErrors: boolean, data: *, error: {}} | {hasErrors: boolean, data: [], error: *}>}
		 */
		async MIX_redis_updateAllAccreditationPassesStatusByEventAndOrganisation(eventId, organisationId, newStatus) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/accreditationPass/update/updateAllAccreditationPassesStatusByEventAndOrganisation/${eventId}/${organisationId}`, newStatus)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error updating All Accreditation Passes Status: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update Accreditation Event Organisation
		 *
		 * Update the Accreditation Event with the Organisation's ID, so it's registered for the event.
		 *
		 * @param accreditationEventId {string} - the Accreditation Event ID
		 * @param organisationId {string} - the Organisation ID
		 */
		async MIX_redis_updateMediaAccreditationEventOrganisation(accreditationEventId, organisationId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/mediaAccreditationEvent/update/accreditationEventOrganisation/${accreditationEventId}/${organisationId}`)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error updating Accreditation Event Organisation: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update Accreditation Pass Print Statuses
		 *
		 * Update the print statuses of the selected Accreditation Passes.
		 * @param payload {object} - the passIds, and the isPrinted flag
		 */
		MIX_redis_updateMediaAccreditationPassPrintStatuses(payload) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/mediaAccreditationPass/update/updateAccreditationPassPrintStatuses`, payload)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error updating Accreditation Pass Print Statuses: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update Organisation User
		 *
		 * Update the Organisation User.
		 * @param userData {object} - the user data
		 */
		async MIX_redis_updateOrganisationUser(userData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/organisation/update/organisationUser`, userData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error updating Organisation User: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update Parking Space Arrival Numbers
		 *
		 * Update the Parking Space document with the new arrival Numbers.
		 */
		async MIX_redis_updateParkingSpaceArrivalNumbers(parkingSpaceId, parkingSpaceData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/parkingSpace/update/updateParkingSpaceArrivalNumbers/${parkingSpaceId}`, parkingSpaceData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error updating Parking Space Arrival Numbers: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update User SWAPP Data
		 *
		 * After SWAPPing, update any other SWAPP data for this User, e.g. EventUser docs.
		 *
		 * @param userData {object} - the User's data
		 */
		async MIX_redis_updateUserSwappData(userData) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/user/update/userSwappData`, {userData})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error updating User SWAPP Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Update User's Team
		 *
		 * Send an array of Users off to the server to have their team associations updated.
		 *
		 * @param formData {object} array of user objects
		 * @param teamId {string} the team ID to associate the Users to
		 * @returns {Promise<AxiosResponse<any> | void>} array of entity object
		 */
		async MIX_redis_updateUsersTeam(formData, teamId) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/user/update/usersTeam/${teamId}`, formData)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error getting all: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Misc processing ---------------------------------------------------------------------------------------------
		 */

		async MIX_redis_convertAndOutputJson(data, redisKey) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/appFunctions/convertAndOutputJson`, {data, redisKey})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting Latest Updates Cards Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		async MIX_redis_createData(redisKey) {
			const t = this

			console.log(`createData data: `, redisKey)

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/appFunctions/createData`, {redisKey})
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				}).catch(error => {
					console.error(`Error getting Latest Updates Cards Data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Process User Data
		 *
		 * Process the data for switching to the new version of SWAPP.
		 */
		async MIX_redis_processData(fileName) {
			const t = this

			return t.$axios.post(`${process.env.VUE_APP_SERVER}/appFunctions/processData`, fileName)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: {}
					}
				})
				.catch(error => {
					console.error(`Error processing data: `, error)
					return {
						data: [],
						hasErrors: true,
						error: error
					}
				})
		},

	},

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
