<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							:clearable="true"
							label="User Name or Id Number"
							style="width: 100%"
							v-model.trim="searchByUserName"/>

			<!--Filter Button - with a numbered badge-->
			<!--<div style="position:relative;">-->
			<!--	<app-btn @click.native="toggleFiltersVisibility"-->
			<!--			 class="ml-4" color="appWhite" icon="filter" icon-color="primary"/>-->
			<!--	<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>-->
			<!--</div>-->

			<!--More Actions Menu-->
			<div v-if="selectedEvent?.entityId" class="ml-4">
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection"
								   :menuList="moreActionsMenuOption"/>
			</div>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Events Dropdown | Event Details-->
		<div class="d-flex align-center mt-4">

			<!--Events Dropdown-->
			<div class="d-flex">
				<app-form-field form-type="autoComplete"
								@onChange="handleEventSelection"
								class="mr-4"
								:items="eventsData"
								item-text="eventName"
								no-data-text="No Archived events found"
								:return-object="true"
								label="Event"/>
			</div>

			<!--Event Details-->
			<div v-if="selectedEvent?.entityId" class="d-flex align-center">
				<app-text>{{ selectedEvent.eventName }}</app-text>
				<app-text class="mx-2" color="greyD">|</app-text>
				<app-text>{{ MIX_formatDate(selectedEvent.eventDate, 'long') }}</app-text>
			</div>

		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.id="{item}">
				<app-text size="small">{{ item.id }}</app-text>
			</template>

			<!--User Name-->
			<template v-slot:item.userName="{item}">
				<div class="my-1">
					<app-text size="small-bold">{{ item.userData.userName }}</app-text>
					<app-text color="grey9" size="small">ID: {{ item.userData.userInternalIdNumber }}</app-text>
					<app-text v-if="item.userData.userPayrollNumber"
							  color="grey9" size="small">PN: {{ item.userData.userPayrollNumber }}</app-text>
					<app-text color="grey9" size="small">{{ item.teamData.teamName }}</app-text>
				</div>
			</template>

			<!--Team Name-->
			<template v-slot:item.teamName="{item}">
				<app-text size="small">{{ item.teamData.teamName }}</app-text>
			</template>

			<!--Pay Rate-->
			<template v-slot:item.payRateHourlyRate="{item}">
				<div class="my-1">

					<!--Normal-->
					<div class="d-flex align-center">
						<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
							Normal -
						</app-text>
						<app-text size="small">
							{{ item.payData.normal.payGrade }} @ £ {{ item.payData.normal.payRate }}
						</app-text>
					</div>

					<!--Extension-->
					<div v-if="item.payData.extension.payGrade" class="d-flex align-center">
						<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
							Extension -
						</app-text>
						<app-text size="small">
							{{ item.payData.extension.payGrade }} @ £ {{ item.payData.extension.payRate }}
						</app-text>
					</div>

					<!--Transfer-->
					<div v-if="item.payData.transfer.payGrade" class="d-flex align-center">
						<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
							Transfer -
						</app-text>
						<app-text size="small">
							{{ item.payData.transfer.payGrade }} @ £ {{ item.payData.transfer.payRate }}
						</app-text>
					</div>

				</div>
			</template>

			<!--Hours Worked-->
			<template v-slot:item.hoursWorked="{item}">
				<div class="my-1">

					<!--Normal-->
					<div class="d-flex align-center">
						<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
							Normal -
						</app-text>
						<app-text size="small">{{ item.payData.normal.hoursWorked }}</app-text>
					</div>

					<!--Extension-->
					<div v-if="item.payData.extension.hoursWorked" class="d-flex align-center">
						<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
							Extension -
						</app-text>
						<app-text size="small">{{ item.payData.extension.hoursWorked }}</app-text>
					</div>

					<!--Transfer-->
					<div v-if="item.payData.transfer.hoursWorked" class="d-flex align-center">
						<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
							Transfer -
						</app-text>
						<app-text size="small">{{ item.payData.transfer.hoursWorked }}</app-text>
					</div>

				</div>
			</template>

			<!--Fee-->
			<template v-slot:item.fee="{item}">
				<app-text v-if="item.payData.normal.allowances.fee === '0'" color="greyD" size="small">None</app-text>
				<app-text v-else size="small">£ {{ item.payData.normal.allowances.fee }}</app-text>
			</template>

			<!--Allowances-->
			<template v-slot:item.allowance="{item}">
				<app-text v-if="item.payData.normal.allowances.allowance === 0" color="greyD" size="small">None
				</app-text>
				<div v-else class="my-1">

					<!--Normal-->
					<div class="d-flex align-center">
						<app-text color="grey9" size="small">£
							{{ payRatesData.find(pr =>
								pr.payRatePayGrade === item.eventUserData.eventUserPayGrade)?.payRateAllowanceRate }}
						</app-text>
						<app-text class="mx-2" color="grey9" size="small">every</app-text>
						<app-text color="grey9" size="small">
							{{ payRatesData.find(pr =>
							pr.payRatePayGrade === item.eventUserData.eventUserPayGrade)?.payRateAllowanceDuration }}
						</app-text>
						<app-text class="mx-2" color="grey9" size="small">hours =</app-text>
						<app-text size="small">£ {{ item.payData.normal.allowances.allowance }}</app-text>
					</div>

				</div>
			</template>

			<!--Total Pay-->
			<template v-slot:item.totalPay="{item}">
				<div class="d-flex align-center">
					<div class="my-1 mr-2 pr-1" style="border-right: 1px solid #DDDDDD">

						<!--Normal-->
						<div class="d-flex align-center">
							<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
								Normal -
							</app-text>
							<app-text size="small" style="width: 9ch">£ {{ item.payData.normal.totalPay }}</app-text>
						</div>

						<!--Extension-->
						<div v-if="item.payData.extension.totalPay !== '0.00'" class="d-flex align-center">
							<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
								Extension -
							</app-text>
							<app-text size="small" style="width: 9ch">£ {{ item.payData.extension.totalPay }}</app-text>
						</div>

						<!--Transfer-->
						<div v-if="item.payData.transfer.totalPay !== '0.00'" class="d-flex align-center">
							<app-text class="text-right mr-1" color="greyD" size="small" style="width: 9ch">
								Transfer -
							</app-text>
							<app-text size="small" style="width: 9ch">£ {{ item.payData.transfer.totalPay }}</app-text>
						</div>

					</div>

					<app-text size="small-bold">£ {{ calculateGrandTotalPay(item) }}</app-text>

				</div>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">


			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.userData?.userName || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel"
							  class="cursorPointer" color="white" icon="close" size="32"/>
				</div>

				<!--Action Bar-->
				<!--<div class="d-flex align-center pa-4">-->
				<!--	<v-spacer/>-->
				<!--	<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>-->
				<!--</div>-->

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Overview-->
					<v-tab href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview-->
					<v-tab-item value="overview">
						<payroll-form :event-data="selectedEvent"
									  :form-data="selectedItem"
									  :is-read-only="isReadOnly"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>

import PayrollPayRatesForm from "@/views/payroll/payRates/payRatesForm/PayrollPayRatesForm.vue";
import PayrollForm from "@/views/payroll/payRoll/payrollForm/PayrollForm.vue";

export default {

	name: "Payroll",

	components: {PayrollForm, PayrollPayRatesForm},

	data: () => ({
		isFiltersPanelVisible: false,
		isLoading: false,
		isReadOnly: false,
		isRightPanelVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByUserName: '',
		selectedEvent: {},
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Name', value: 'userName', align: 'start', sortable: false},
			// {text: 'Team', value: 'teamName', align: 'start', sortable: false},
			{text: 'Pay Rate', value: 'payRateHourlyRate', align: 'start', sortable: false},
			{text: 'Hours', value: 'hoursWorked', align: 'start', sortable: false},
			{text: 'Fee', value: 'fee', align: 'start', sortable: false},
			{text: 'Allowance', value: 'allowance', align: 'start', sortable: false},
			{text: 'Total', value: 'totalPay', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		// Data
		eventsData: [],
		fullPayRollData: [],
		payRatesData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				event: 'Event',
				team: 'Team',
				user: 'User',
				internalIdNumber: 'Steward Number',
				payrollNumber: 'Payroll Number',

				normalPayGrade: 'Normal Pay Grade',
				normalPayRate: 'Normal Pay Rate',
				normalHolidayRate: 'Normal Holiday Rate',
				normalHoursWorked: 'Normal Hours Worked',

				extensionPayGrade: 'Extension Pay Grade',
				extensionPayRate: 'Extension Pay Rate',
				extensionHoursWorked: 'Extension Hours Worked',

				transferPayGrade: 'Transfer Pay Grade',
				transferPayRate: 'Transfer Pay Rate',
				transferHoursWorked: 'Transfer Hours Worked',

				shiftFee: 'Shift Fee',
				allowanceRate: 'Allowance Rate',
				allowanceDuration: 'Allowance Duration',

				eventTotalPay: 'Event Total Pay'
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					event: t.selectedEvent.eventName,
					team: entry.teamData.teamName,
					user: entry.userData.userName,
					internalIdNumber: entry.userData.userInternalIdNumber,
					payrollNumber: entry.userData.userPayrollNumber,

					normalPayGrade: entry.payData.normal.payGrade,
					normalPayRate: entry.payData.normal.payRate,
					normalHolidayRate: t.payRatesData.find(pr => pr.payRatePayGrade === entry.payData.normal.payGrade)?.payRateHolidayRate,
					normalHoursWorked: entry.payData.normal.hoursWorked,

					extensionPayGrade: entry.payData.extension.payGrade,
					extensionPayRate: entry.payData.extension.payRate,
					extensionHoursWorked: entry.payData.extension.hoursWorked,

					transferPayGrade: entry.payData.transfer.payGrade,
					transferPayRate: entry.payData.transfer.payRate,
					transferHoursWorked: entry.payData.transfer.hoursWorked,

					shiftFee: entry.payData.normal.allowances.fee,
					allowanceRate: t.payRatesData.find(pr => pr.payRatePayGrade === entry.eventUserData.eventUserPayGrade)?.payRateAllowanceRate,
					allowanceDuration: t.payRatesData.find(pr => pr.payRatePayGrade === entry.eventUserData.eventUserPayGrade)?.payRateAllowanceDuration,

					eventTotalPay: t.calculateGrandTotalPay(entry)
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const EVENT_DATA = t.selectedEvent
			const FULL_PAYROLL_DATA = t.fullPayRollData?.fullTeamsData
			let tableData = []

			// Create a new array of objects
			FULL_PAYROLL_DATA?.forEach(fullTeamEventData => {

				const TEAM_DATA = fullTeamEventData.teamData
				const EVENT_TEAM_DATA = fullTeamEventData.eventTeamData

				fullTeamEventData.fullUsersData.forEach(user => {

					const USER_PAY_OBJECT = {
						eventData: EVENT_DATA,
						userData: user.userData,
						teamData: TEAM_DATA,
						eventTeamData: EVENT_TEAM_DATA,
						eventUserData: user.eventUserData,
						eventTransferData: user.eventTransferData,
						eventExtensionData: user.eventExtensionData,
						payData: {}
					}

					USER_PAY_OBJECT.payData = t.calculatePay(USER_PAY_OBJECT)

					tableData.push(USER_PAY_OBJECT)
				})

			})

			// Search by UserName or Internal ID
			if (t.searchByUserName) {
				const SEARCH_CRITERIA = t.searchByUserName.toUpperCase()
				tableData = tableData.filter(item => {
					return item.userData.userName.toUpperCase().includes(SEARCH_CRITERIA)
						|| item.userData.userInternalIdNumber.toUpperCase().includes(SEARCH_CRITERIA)
				})
			}

			// Sort the table data by userName
			tableData.sort((a, b) => (a.userData.userName > b.userData.userName) ? 1 : -1)

			return tableData
		}

	},

	methods: {

		/**
		 * Calculate Extension Hours Worked
		 *
		 * Calculate the hours worked for an extension.
		 *
		 * @param userPayObject {object} the user pay object
		 * @returns {number|*} the hours worked
		 */
		calculateExtensionHoursWorked(userPayObject) {
			return userPayObject.eventExtensionData.eventExtensionHours
		},

		/**
		 * Calculate Extension Total Pay
		 *
		 * Calculate the total pay for an extension.
		 *
		 * @param hours {number} the hours worked
		 * @param rate {number} the pay rate
		 * @returns {string} the total pay
		 */
		calculateExtensionTotalPay(hours, rate) {
			return (hours * rate).toFixed(2)
		},

		/**
		 * Calculate Grand Total Pay
		 *
		 * Calculate the grand total pay for an item.
		 *
		 * @param item {object} the item
		 * @returns {string} the grand total pay
		 */
		calculateGrandTotalPay(item) {
			let totalPay = 0

			totalPay += Number(item.payData.normal.totalPay)
			totalPay += Number(item.payData.extension.totalPay)
			totalPay += Number(item.payData.transfer.totalPay)

			return totalPay.toFixed(2)
		},

		/**
		 * Calculate Normal Hours Worked
		 *
		 * Calculate the normal hours worked.
		 * Chief Stewards, Deputy Chief Stewards, and Senior Supervisors always get 8 hours pay.
		 * No shows, late arrivals, and early finishes are accounted for.
		 * If the finish time is before the start time, it is assumed to be an overnight shift,
		 * therefore 24 hours are added to the finish time.
		 *
		 * @param userPayObject {object} the user pay object
		 * @returns {string} the hours worked
		 */
		calculateNormalHoursWorked(userPayObject) {
			const t = this

			// If the user's pay grade is 'Chief Steward', 'Deputy Chief Steward', or 'Senior Supervisor', they always get 8 hours pay
			if (['Chief Steward', 'Deputy Chief Steward', 'Senior Supervisor'].includes(userPayObject.eventUserData.eventUserPayGrade)) return '8:00'

			// Get start and finish times
			const {
				startTime: START_TIME,
				finishTime: FINISH_TIME,
			} = t.getStartAndFinishTimes(userPayObject)

			let hoursWorked = 0

			// If the user was a no-show, set the hours worked to 0
			if (userPayObject.eventUserData.eventUserIsNoShow) return '0:00'

			// Initialise lateTime and earlyFinishTime
			const LATE_TIME = userPayObject.eventUserData.eventUserLateArrivalTime !== "0" ? userPayObject.eventUserData.eventUserLateArrivalTime : null
			const EARLY_FINISH_TIME = userPayObject.eventUserData.eventUserEarlyFinishTime !== "0" ? userPayObject.eventUserData.eventUserEarlyFinishTime : null

			// Calculate the actual start and finish times
			const ACTUAL_START_TIME = LATE_TIME || START_TIME
			const ACTUAL_FINISH_TIME = EARLY_FINISH_TIME || FINISH_TIME

			// Convert times to minutes
			let startMinutes = t.timeStringToMinutes(ACTUAL_START_TIME)
			let finishMinutes = t.timeStringToMinutes(ACTUAL_FINISH_TIME)

			// Adjust for overnight shifts by adding 24 hours to the finish time
			if (finishMinutes < startMinutes) finishMinutes += 24 * 60

			// Calculate the hours worked in minutes
			const HOURS_WORKED_MINUTES = finishMinutes - startMinutes
			hoursWorked = t.minutesToTimeString(HOURS_WORKED_MINUTES)

			return hoursWorked
		},

		/**
		 * Calculate Normal Total Pay
		 *
		 * Calculate the total pay for normal hours worked.
		 *
		 * @param hours {string} the hours worked
		 * @param rate {number} the pay rate
		 * @param allowances {object} the allowances
		 * @returns {string} the total pay
		 */
		calculateNormalTotalPay(hours, rate, allowances) {
			const t = this

			hours = t.timeStringToMinutes(hours) / 60

			return ((hours * rate) + Number(allowances.fee) + Number(allowances.allowance)).toFixed(2)
		},

		/**
		 * Calculate Pay
		 *
		 * Calculate the pay for a user.
		 * The pay is calculated based on the user's role, the hours worked, the pay rates, and any fees/allowances.
		 * The pay is calculated for normal hours worked, extension hours worked, and transfer hours worked.
		 *
		 * @param userPayObject {object} the user pay object
		 * @returns {{extension: {hoursWorked: number, payGrade: string, payRate: number, totalPay: number}, normal: {hoursWorked: number, payGrade: string, payRate: number, totalPay: number, allowances: {fee: number, allowance: number}}, transfer: {hoursWorked: number, payGrade: string, payRate: number, totalPay: number}}
		 */
		calculatePay(userPayObject) {
			const t = this
			const EXTENSION_DATA = userPayObject.eventExtensionData
			const PAY_RATES_DATA = t.payRatesData
			const TRANSFER_DATA = userPayObject.eventTransferData

			const PAY = {
				normal: {
					hoursWorked: t.calculateNormalHoursWorked(userPayObject),
					payGrade: userPayObject.eventUserData.eventUserPayGrade,
					payRate: t.calculatePayRate(userPayObject.eventUserData.eventUserPayGrade),
					totalPay: 0,
					allowances: t.calculateFeesAndAllowances(userPayObject.eventUserData.eventUserPayGrade, t.calculateNormalHoursWorked(userPayObject), PAY_RATES_DATA)
				},
				extension: {
					hoursWorked: EXTENSION_DATA?.entityId ? t.calculateExtensionHoursWorked(userPayObject) : 0,
					payGrade: EXTENSION_DATA?.eventExtensionRoleTo || '',
					payRate: EXTENSION_DATA?.entityId ? t.calculatePayRate(EXTENSION_DATA.eventExtensionRoleTo) : 0,
					totalPay: 0
				},
				transfer: {
					hoursWorked: TRANSFER_DATA?.entityId ? t.calculateTransferHoursWorked(userPayObject) : 0,
					payGrade: TRANSFER_DATA?.eventTransferRoleTo || '',
					payRate: TRANSFER_DATA?.entityId ? t.calculatePayRate(TRANSFER_DATA.eventTransferRoleTo) : 0,
					totalPay: 0
				}
			}

			PAY.normal.totalPay = t.calculateNormalTotalPay(PAY.normal.hoursWorked, PAY.normal.payRate, PAY.normal.allowances)
			PAY.extension.totalPay = t.calculateExtensionTotalPay(PAY.extension.hoursWorked, PAY.extension.payRate)
			PAY.transfer.totalPay = t.calculateTransferTotalPay(PAY.transfer.hoursWorked, PAY.transfer.payRate)

			return PAY
		},

		/**
		 * Calculate Fees and Allowances
		 *
		 * Calculate the fees and allowances for a user.
		 * The fees and allowances are calculated based on the user's pay grade, the hours worked, and the pay rates data.
		 *
		 * @param payGrade {string} the user's pay grade
		 * @param hoursWorked {string} the hours worked
		 * @param payRatesData {array} the pay rates data
		 * @returns {{allowance: number, fee: number}}
		 */
		calculateFeesAndAllowances(payGrade, hoursWorked, payRatesData) {
			const t = this

			// Get the pay rate data for the pay grade
			const PAY_RATE_DATA = payRatesData.find(pr => pr.payRatePayGrade === payGrade)

			// Initialize fees and allowances object
			const FEES_AND_ALLOWANCES = {
				fee: 0,
				allowance: 0
			}

			// Calculate flat fee
			if (PAY_RATE_DATA && PAY_RATE_DATA.payRateFee) FEES_AND_ALLOWANCES.fee = PAY_RATE_DATA.payRateFee

			// Calculate allowances based on hours worked and allowance rate
			if (PAY_RATE_DATA && PAY_RATE_DATA.payRateAllowanceRate !== '0' && PAY_RATE_DATA.payRateAllowanceDuration !== '0') {

				const ALLOWANCE_RATE = PAY_RATE_DATA.payRateAllowanceRate
				const ALLOWANCE_DURATION = PAY_RATE_DATA.payRateAllowanceDuration

				// Convert hours worked to minutes for easier calculation
				const HOURS_WORKED_IN_MINUTES = t.timeStringToMinutes(hoursWorked)

				// Calculate the number of complete allowance periods within the hours worked
				const NUM_ALLOWANCE_PERIODS = Math.floor(HOURS_WORKED_IN_MINUTES / (ALLOWANCE_DURATION * 60))

				// Calculate total allowances
				FEES_AND_ALLOWANCES.allowance = (NUM_ALLOWANCE_PERIODS * ALLOWANCE_RATE).toFixed(2)
			}

			return FEES_AND_ALLOWANCES
		},

		/**
		 * Calculate Pay Rate
		 *
		 * Calculate the pay rate for a user based on their role.
		 * The pay rate is calculated based on the user's role and the pay rates data.
		 *
		 * @param role {string} the user's role
		 * @returns {string} the pay rate
		 */
		calculatePayRate(role) {
			const t = this

			const HOURLY_RATE = t.getHourlyRates(role).hourlyRate
			const HOLIDAY_RATE = t.getHourlyRates(role).holidayRate

			return (HOURLY_RATE + HOLIDAY_RATE).toFixed(2)
		},

		/**
		 * Calculate Transfer Hours Worked
		 *
		 * Calculate the hours worked for a transfer.
		 *
		 * @param userPayObject {object} the user pay object
		 * @returns {number|*} the hours worked
		 */
		calculateTransferHoursWorked(userPayObject) {
			return userPayObject.eventTransferData.eventTransferHours
		},

		/**
		 * Calculate Transfer Total Pay
		 *
		 * Calculate the total pay for a transfer.
		 *
		 * @param hours {number} the hours worked
		 * @param rate {number} the pay rate
		 * @returns {string} the total pay
		 */
		calculateTransferTotalPay(hours, rate) {
			return (hours * rate).toFixed(2)
		},

		/**
		 * Get Hourly Rates
		 *
		 * Get the hourly rates for a pay grade.
		 *
		 * @param payGrade {string} the pay grade
		 * @returns {{hourlyRate: number, holidayRate: number}}
		 */
		getHourlyRates(payGrade) {
			const t = this
			const PAY_RATES_DATA = t.payRatesData

			// If the payGrade is 'User', set it to 'Steward'
			// This is due to how the Pay Rates vs User Roles are stored in the database
			if (payGrade === 'User') payGrade = 'Steward'
			if (payGrade === 'Deputy') payGrade = 'Deputy Supervisor'

			const HOURLY_RATE = Number(PAY_RATES_DATA?.find(pr => pr.payRatePayGrade === payGrade).payRateHourlyRate || 0)
			const HOLIDAY_RATE = Number(PAY_RATES_DATA?.find(pr => pr.payRatePayGrade === payGrade).payRateHolidayRate || 0)

			return {hourlyRate: HOURLY_RATE, holidayRate: HOLIDAY_RATE}
		},

		/**
		 * Get Start and Finish Times
		 *
		 * Get the start and finish times for a user.
		 * The start and finish times are determined based on the user's role and the event team data.
		 * The order of precedence is as follows:
		 * - User's start and finish times
		 * - The team's start and finish times
		 * - The event's start and finish times
		 *
		 * @param userPayObject {object} the user pay object
		 * @returns {{startTime: string, finishTime: string, shiftLength: string}}
		 */
		getStartAndFinishTimes(userPayObject) {
			let startTime, finishTime, shiftLength

			// NOTE!
			// We're not using the eventUser signInTime as stewards may not be signed in at the time of arrival.
			// So, as long as they haven't been marked as late, their start time should be as per the eventUser/eventTeam/event start time

			// Determine if the user is a Deputy Supervisor or Supervisor
			const IS_SUPERVISOR = ['Deputy Supervisor', 'Supervisor'].includes(userPayObject.eventUserData.eventUserPayGrade)

			// Set start time, finish time, and shift length based on the role
			startTime = IS_SUPERVISOR
				? (userPayObject.eventUserData.eventUserStartTime || userPayObject.eventTeamData.eventTeamSupervisorsStartTime || userPayObject.eventData.eventSupervisorsStartTime)
				: (userPayObject.eventUserData.eventUserStartTime || userPayObject.eventTeamData.eventTeamStewardsStartTime || userPayObject.eventData.eventStewardsStartTime)

			finishTime = userPayObject.eventUserData.eventUserSignOutTime !== "0"
				? userPayObject.eventUserData.eventUserSignOutTime
				: IS_SUPERVISOR
					? (userPayObject.eventUserData.eventUserFinishTime || userPayObject.eventTeamData.eventTeamSupervisorsFinishTime || userPayObject.eventData.eventSupervisorsFinishTime)
					: (userPayObject.eventUserData.eventUserFinishTime || userPayObject.eventTeamData.eventTeamStewardsFinishTime || userPayObject.eventData.eventStewardsFinishTime)

			shiftLength = IS_SUPERVISOR
				? userPayObject.eventTeamData.eventTeamSupervisorsShiftLength || userPayObject.eventData.eventSupervisorsShiftLength
				: userPayObject.eventTeamData.eventTeamStewardsShiftLength || userPayObject.eventData.eventStewardsShiftLength

			return {startTime, finishTime, shiftLength}
		},

		/**
		 * Minutes to Time String
		 *
		 * Convert minutes to a time string.
		 *
		 * @param minutes {number} the minutes
		 * @returns {string} the time string
		 */
		minutesToTimeString(minutes) {
			return Math.trunc(minutes / 60) + ':' + ('00' + minutes % 60).slice(-2)
		},

		/**
		 * Time String to Minutes
		 *
		 * Convert a time string to minutes.
		 *
		 * @param timeString {string} the time string
		 * @returns {number} the minutes
		 */
		timeStringToMinutes(timeString) {
			const [hours, minutes] = timeString.split(":").map(Number)
			return hours * 60 + minutes
		},

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByArea = ''
			t.filterByDeputy = ''
			t.filterByQuotaLessThan = null
			t.filterByQuotaMoreThan = null
			t.filterBySteward = ''
			t.filterBySupervisor = ''
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Payroll', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Handle Event Selection
		 *
		 * Handle the event selection by loading the Payroll data.
		 * Returns the full Payroll data:
		 *  - Event Data
		 *  - Event Teams Data
		 *
		 *
		 *
		 * @param value the selected event
		 */
		async handleEventSelection(value) {
			const t = this
			t.selectedEvent = value

			t.isLoading = true

			const RESPONSE = await t.MIX_redis_getPayrollData(value.entityId)

			t.isLoading = false

			if (RESPONSE?.hasErrors) {
				console.error('Error loading Payroll data: ', RESPONSE?.errors)
				t.$sharedState.errorMessage = 'There was an error loading the Payroll data, please try again.'
				return
			}

			t.fullPayRollData = RESPONSE?.data
		},

		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadEventsData(),
				t.loadPayRatesData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Events Data
		 *
		 * Load the events data.
		 */
		async loadEventsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Event',
				[{whereKey: 'eventStatus', whereValue: 'Archived'}],
				[
					{field: 'eventName'},
					{field: 'eventDate'},
					{field: 'eventStartTime'},
					{field: 'eventFinishTime'},
					{field: 'eventStewardsStartTime'},
					{field: 'eventStewardsFinishTime'},
					{field: 'eventStewardsShiftLength'},
					{field: 'eventSupervisorsStartTime'},
					{field: 'eventSupervisorsFinishTime'},
					{field: 'eventSupervisorsShiftLength'},
					{field: 'createdDateTime'}]
			)

			// Handle any errors
			if (RESPONSE?.hasErrors) {
				console.error('Error loading Events data: ', RESPONSE?.errors)
				t.$sharedState.errorMessage = 'There was an error loading the events data, please try again.'
				return
			}

			// Sort the events by date
			RESPONSE.data.sort((a, b) => a.eventDate < b.eventDate ? 1 : -1)

			t.eventsData = RESPONSE?.data
		},

		/**
		 * Load Pay Rates Data
		 *
		 * Load the pay rates data.
		 */
		async loadPayRatesData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'PayRate',
				[],
				[]
			)

			// Handle any errors
			if (RESPONSE?.hasErrors) {
				console.error('Error loading Pay Rates data: ', RESPONSE?.errors)
				t.$sharedState.errorMessage = 'There was an error loading the Pay Rates data, please try again.'
				return
			}

			t.payRatesData = RESPONSE?.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			// New
			if (!item?.teamData?.entityId) {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			// Existing
			else {
				t.isReadOnly = true
				t.selectedItem = item
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		t.loadData()
	}

}
</script>

<style scoped>

</style>
