<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructional Text-->
		<app-text>
			You are about to update the Supervisor's and Deputy's Start/Finish times.
			<br>
			<br>
			They are currently starting at <strong>{{ startTime }}</strong>
			and finishing at <strong>{{ finishTime }}</strong>.
			<br>
			<br>
			<span class="font-weight-bold">Select the new times from the pickers below</span>
		</app-text>

		<!--Time Pickers-->
		<div class="d-flex align-center mt-4">

			<time-picker2 @emitTime="handleTimeSelection($event, 'newStartTime')"
						  :clearable="false"
						  :error="errors.startTime"
						  :error-message="errors.startTimeErrorMessage"
						  label="Start Time"
						  :time="newStartTime"/>

			<app-text class="mx-4">-</app-text>

			<time-picker2 @emitTime="handleTimeSelection($event, 'newFinishTime')"
						  :clearable="false"
						  :error="errors.startTime"
						  :error-message="errors.finishTimeErrorMessage"
						  label="Finish Time"
						  :time="newFinishTime"/>

		</div>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Accept-->
			<app-btn @click.native="handleUpdateSupervisorsTimes"
					 color="green"
					 icon="save"
					 label="Save"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventSupervisorsTimesDialog",

	props: ['finishTime', 'startTime'],

	data: () => ({
		errors: {
			startTime: false,
			startTimeErrorMessage: '',
			finishTime: false,
			finishTimeErrorMessage: '',
		},
		newStartTime: '',
		newFinishTime: '',
	}),

	computed: {

		computedInit() {
			const t = this
			const START_TIME = t.$props.startTime
			const FINISH_TIME = t.$props.finishTime

			t.newStartTime = START_TIME
			t.newFinishTime = FINISH_TIME
		}

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
		closeDialog() {
			const t = this

			// Reset the times
			t.newStartTime = t.$props.startTime
			t.newFinishTime = t.$props.finishTime

			t.$emit('emitCloseDialog')
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 * Validate the time format, so if the min or max is set, the time must be within that range.
		 *
		 * @param time {string} the time to set
		 * @param min {string} the minimum time
		 * @param max {string} the maximum time
		 * @param field {string} the form field to set
		 */
		handleTimeSelection({time, min, max}, field) {
			const t = this

			// Clear any errors
			t.errors[field] = false
			t.errors[field + 'ErrorMessage'] = ''

			// Validate the time format
			if (min && time < min) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be after ' + min
				return
			}

			// Validate the time format
			if (max && time > max) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be before ' + max
				return
			}

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Handle Positive Action
		 *
		 * Emit a message back to the parent component to submit the Event.
		 */
		handleUpdateSupervisorsTimes() {
			const t = this

			if (!t.validateForm()) return

			t.$emit('emitUpdateSupervisorsTimes', {newFinishTime: t.newFinishTime, newStartTime: t.newStartTime})
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Start and Finish Time - Start cannot be after Finish
			if (t.newStartTime > t.newFinishTime) {
				t.errors.startTime = true
				t.errors.startTimeErrorMessage = 'Start Time cannot be after Finish Time'

				t.errors.finishTime = true
				t.errors.finishTimeErrorMessage = 'Finish Time cannot be before Start Time'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
