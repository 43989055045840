<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<app-text v-if="fullReportData?.eventData && !fullReportData?.eventData?.entityId"
				  class="mb-4" color="red" size="small">
			NOTE: This is an old record and some data may not have been captured at the time.
		</app-text>

		<!--Form-->
		<v-row no-gutters>

			<!--Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Name"
								v-model.trim="form.createdUserName"/>
			</v-col>

			<!--Team-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:items="teamsData"
								item-text="teamName"
								label="Team"
								:return-object="true"
								v-model="displayTeamName"/>
			</v-col>

			<!--Event-->
			<!--WATCHED PROPERTY-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.ejectionReportEvent"
								:error-message="errors.ejectionReportEventErrorMessage"
								:items="eventsData"
								item-text="eventName"
								label="Event"
								:return-object="true"
								v-model="selectedEventData"/>
			</v-col>

			<!--Date-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								:error="errors.ejectionReportDate"
								:error-message="errors.ejectionReportDateErrorMessage"
								label="Date"
								v-model="displayEventDate"/>
			</v-col>

			<!--Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<time-picker2 @emitTime="handleTimeSelection($event, 'ejectionReportTime')"
							 :disabled="isReadOnly"
							 :error="errors.ejectionReportTime"
							 :error-message="errors.ejectionReportTimeErrorMessage"
							 label="Ejection Time"
							 :max="new Date().getHours() + ':' + new Date().getMinutes()"
							 :time="form.ejectionReportTime"/>
			</v-col>

			<!--Site-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.ejectionReportSite"
								:error-message="errors.ejectionReportSiteErrorMessage"
								:items="sitesData"
								item-text="siteName"
								label="Site"
								v-model="form.ejectionReportSite"/>
			</v-col>

			<!--Location-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.ejectionReportLocation"
								:error-message="errors.ejectionReportLocationErrorMessage"
								label="Location"
								v-model.trim="form.ejectionReportLocation"/>
			</v-col>

			<!--Person Ejected-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.ejectionReportPerson"
								:error-message="errors.ejectionReportPersonErrorMessage"
								label="Person Ejected"
								placeholder="Name, or Short description of person"
								v-model.trim="form.ejectionReportPerson"/>
			</v-col>

			<!--Reason for Ejection-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.ejectionReportReason"
								:error-message="errors.ejectionReportReasonErrorMessage"
								label="Reason for Ejection"
								placeholder="Please add as much detail as you can about the ejection"
								rows="8"
								v-model.trim="form.ejectionReportReason"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
				<app-btn v-if="!isReadOnly"
						 @click.native="handleSaveItem"
						 color="green"
						 icon="save"
						 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "EjectionForm",

	props: ['eventsData', 'fullReportData', 'isReadOnly', 'sitesData', 'teamsData'],

	data: () => ({
		errors: {
			ejectionReportDate: false,
			ejectionReportDateErrorMessage: '',
			ejectionReportEvent: false,
			ejectionReportEventErrorMessage: '',
			ejectionReportLocation: false,
			ejectionReportLocationErrorMessage: '',
			ejectionReportPerson: false,
			ejectionReportPersonErrorMessage: '',
			ejectionReportReason: false,
			ejectionReportReasonErrorMessage: '',
			ejectionReportSite: false,
			ejectionReportSiteErrorMessage: '',
			ejectionReportTime: false,
			ejectionReportTimeErrorMessage: '',
		},
		form: {
			entityId: '',

			ejectionReportTeam: '',
			ejectionReportEvent: '',
			ejectionReportDate: 0,
			ejectionReportTime: '',
			ejectionReportSite: '',
			ejectionReportLocation: '',
			ejectionReportPerson: '',
			ejectionReportReason: '',

			createdUserId: '',
			createdUserName: '',
			createdDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			modifiedDateTime: 0,
			isDeleted: false,
			deletedUserId: '',
			deletedUserName: '',
			deletedDateTime: 0,
		},
		selectedEventData: {},
		displayTeamName: '',
		displayEventDate: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.fullReportData
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			if (FORM_DATA?.reportData?.entityId) {
				t.form = FORM_DATA.reportData
				t.form.createdUserId = FORM_DATA.reportData.createdUserId
				t.form.createdUserName = FORM_DATA.reportData.createdUserName
				t.form.ejectionReportTeam = FORM_DATA.reportData.ejectionReportTeam
				t.displayTeamName = FORM_DATA.teamData?.teamName
				t.selectedEventData = FORM_DATA.eventData
			} else {
				t.form.createdUserId = CURRENT_USER_DATA.entityId
				t.form.createdUserName = CURRENT_USER_DATA.userName
			}
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		handleSaveItem() {
			const t = this

			if (!t.validateForm()) return

			// Save or Update the form
			t.form.ejectionReportTeam = t.displayTeamName.entityId

			if (!t.form.entityId) t.saveItem()
			else if (t.form.entityId) t.updateItem()
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 * Validate the time format, so if the min or max is set, the time must be within that range.
		 *
		 * @param time {string} the time to set
		 * @param min {string} the minimum time
		 * @param max {string} the maximum time
		 * @param field {string} the form field to set
		 */
		handleTimeSelection({time, min, max}, field) {
			const t = this

			// Clear any errors
			t.errors[field] = false
			t.errors[field + 'ErrorMessage'] = ''

			// Validate the time format
			if (min && time < min) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be after ' + min
				return
			}

			// Validate the time format
			if (max && time > max) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be before ' + max
				return
			}

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Save Item
		 *
		 * Save the form to the DB.
		 */
		async saveItem() {
			const t = this
			const FORM_DATA = t.form

			const RESPONSE = await t.MIX_redis_create('ejectionReport', FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Ejection Report: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Created Report'

			t.$emit('emitReloadPage')
		},

		/**
		 * Update Item
		 *
		 * Update the form to the DB.
		 */
		async updateItem() {
			const t = this
			const FORM_DATA = t.form

			const RESPONSE = await t.MIX_redis_update('ejectionReport', FORM_DATA.entityId, FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Ejection Report: ', RESPONSE.error)
				return
			}

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Event
			if (!t.form.ejectionReportEvent) {
				t.errors.ejectionReportEvent = true
				t.errors.ejectionReportEventErrorMessage = 'Required'
			}

			// Time
			if (!t.form.ejectionReportTime) {
				t.errors.ejectionReportTime = true
				t.errors.ejectionReportTimeErrorMessage = 'Time is required'
			}

			// Site
			if (!t.form.ejectionReportSite) {
				t.errors.ejectionReportSite = true
				t.errors.ejectionReportSiteErrorMessage = 'Site is required'
			}

			// Location
			if (!t.form.ejectionReportLocation) {
				t.errors.ejectionReportLocation = true
				t.errors.ejectionReportLocationErrorMessage = 'Location is required'
			}

			// Person Ejected
			if (!t.form.ejectionReportPerson) {
				t.errors.ejectionReportPerson = true
				t.errors.ejectionReportPersonErrorMessage = 'Person is required'
			}

			// Description
			if (!t.form.ejectionReportReason) {
				t.errors.ejectionReportReason = true
				t.errors.ejectionReportReasonErrorMessage = 'Description is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	watch: {

		/**
		 * Selected Event Data
		 *
		 * When an Event is selected from the dropdown, set the required form data.
		 * Also set the display data (formatted event date)
		 *
		 * @param eventData - the selected event data
		 */
		selectedEventData(eventData) {
			const t = this

			t.form.ejectionReportEvent = eventData?.entityId
			t.form.ejectionReportDate = eventData?.eventDate || t.$props.fullReportData?.reportData?.ejectionReportDate || t.$props.fullReportData?.reportData?.createdDateTime

			t.displayEventDate = t.MIX_formatDate(t.form.ejectionReportDate, 'short')
		}

	},

}
</script>

<style scoped>

</style>
