<!--
Event Team Notes Dialog

This dialog is used in the Event Availability Card component, and displays the team notes for the event.
It is only viewable be Steward-Supervisors and Steward-Deputies.

Props:
- teamNotes: String - The team notes for the event.
-->
<template>
	<div class="appGrey rounded-lg pa-4">

		<app-text>{{ teamNotes ? teamNotes : 'There are no team notes to view.' }}</app-text>

		<v-divider class="mt-4"/>

		<!--Close Button-->
		<app-btn @click.native="closeDialog"
				 :block="true"
				 class="mt-4"
				 label="Close"/>

	</div>
</template>

<script>

export default {

	name: "EventTeamNotesDialog",

	props: ['teamNotes'],

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseTeamNotesDialog')
		},

	},

}
</script>

<style scoped>

</style>
