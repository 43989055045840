<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<component is="style">
			@media print {

			@page {
			margin: 0;

			size: A4 landscape;
			}

			.noPrint {
			display: none;
			}

			}
		</component>
		<v-card class="oppp-card" flat id="page">

			<!--Toolbar-->
			<v-toolbar class="noPrint px-3" color="appGrey">

				<app-text color="primary" size="medium-bold">Pass Print Preview</app-text>

				<v-spacer/>

				<app-btn @click.native="printPass('printableArea')"
						 color="primary"
						 height="40"
						 icon="print"
						 icon-color="white"
						 label="Print"
						 label-color="white"/>

				<close-icon class="ml-4" @click.native="closeDialog"/>

			</v-toolbar>

			<!--Body-->
			<component is="style">
				.accPass-container-outer {
				<!--display: grid;-->
				<!--grid-template-columns: 1fr 1fr;-->
				height: 15cm;
				width: 22cm;
				}
			</component>
			<div class="accPass-container-outer" id="printableArea">

				<!-- -------------------------------------- -->
				<!-- Everything from here down gets printed -->
				<!-- -------------------------------------- -->

				<!--Main Container-->
				<component is="style">
					.accPass-main-container {
					display: grid;
					grid-template-columns: 1fr 1fr;
					height: 15cm;
					page-break-before: always;
					width: 22cm;
					}
				</component>
				<div v-for="item in passesData" class="accPass-main-container">

					<!--Front Container-->
					<component is="style">
						.accPass-front-container {
						background: white;
						border: 1px solid lightgrey;
						display: grid;
						grid-template-rows: 42mm auto auto 1fr;
						height: 15cm;
						width: 11cm;
						}
					</component>
					<div class="accPass-front-container">

						<!--Header Container-->
						<component is="style">
							.accPass-header-container {
							display: grid;
							grid-template-columns: 1fr 1fr;
							margin: 4mm 4mm 0 4mm;
							width: calc(100% - 8mm);
							}
						</component>
						<div class="accPass-header-container">

							<!--Header Image Container-->
							<component is="style">
								.accPass-header-image-container {
								<!--border: 1px solid #000055;-->
								border: 1px solid #EEEEEE;
								display: flex;
								align-items: center;
								justify-content: center;
								margin: 0 auto;
								}
							</component>

							<!--Header Image-->
							<component is="style">
								.accPass-header-image {
								aspect-ratio: 1;
								}
							</component>

							<!--Company Logo Container-->
							<component is="style">
								.accPass-header-image-companyLogo-container {
								border-radius: 4mm 0 0 4mm;
								}
							</component>
							<div class="accPass-header-image-container accPass-header-image-companyLogo-container">

								<!--Company Logo Image-->
								<img :src="require('@/assets/images/company-logo.svg')"
									 alt="event background"
									 class="accPass-header-image"
									 style="object-fit: contain"
									 width="75%"/>

							</div>

							<!--Event Image Container-->
							<component is="style">
								.accPass-header-image-eventImage-container {
								border-radius: 0 4mm 4mm 0;
								}
							</component>
							<div class="accPass-header-image-container accPass-header-image-eventImage-container">

								<!--Event Details Image-->
								<img
									:src="MIX_getImagePath('eventBackground', eventImageData.fileName, eventImageData.fileToken)"
									alt="event background"
									class="accPass-header-image"
									style="object-fit: contain"
									width="75%"/>

							</div>

						</div>

						<!--Event Name Container-->
						<component is="style">
							.accPass-eventName-container {
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 4mm 4mm 0 4mm;
							}
						</component>
						<div class="accPass-eventName-container">

							<!--Event Details Name-->
							<component is="style">
								.accPass-eventName {
								font-family: Avenir, Helvetica, Arial, sans-serif;
								font-size: 16pt !important;
								font-weight: bold;
								}
							</component>
							<app-text class="accPass-eventName">{{ eventData.eventName }}</app-text>

						</div>

						<!--User Details-->
						<component is="style">
							.accPass-userDetails-container {
							display: grid;
							grid-template-columns: 1fr auto;
							margin: 4mm;
							}
						</component>
						<div class="accPass-userDetails-container">

							<!--User Text-->
							<component is="style">
								.accPass-userText-container {
								display: grid;
								grid-template-rows: auto 1fr;
								}
							</component>
							<div class="accPass-userText-container">

								<!--User Name-->
								<component is="style">
									.accPass-user-nameText {
									font-family: Avenir, Helvetica, Arial, sans-serif;
									-webkit-font-smoothing: antialiased;
									-moz-osx-font-smoothing: grayscale;
									color: #333333;
									font-size: 16pt !important;
									}
								</component>
								<!--<app-text class="accPass-user-text"-->
								<!--		  :style="{'font-size': calculateFontSize(item.userData.userName), lineHeight: '104px'}">-->
								<!--	{{ item.userData.userName }}-->
								<!--</app-text>-->
								<app-text class="accPass-user-nameText">{{ item.userData.userName }}</app-text>

								<!--Organisation Name-->
								<component is="style">
									.accPass-user-orgText {
									font-family: Avenir, Helvetica, Arial, sans-serif;
									-webkit-font-smoothing: antialiased;
									-moz-osx-font-smoothing: grayscale;
									color: #333333;
									font-size: 12pt !important;
									margin-top: 2mm;
									}
								</component>
								<app-text class="accPass-user-orgText">{{ item.organisationData.organisationName }}
								</app-text>

							</div>

							<!--User Profile Picture Container-->
							<component is="style">
								.accPass-profilePicture-container {
								aspect-ratio: 1;
								display: flex;
								align-items: center;
								justify-content: center;
								margin: auto;
								width: 80%;
								}
							</component>
							<div class="accPass-profilePicture-container">

								<!--User Profile Picture-->
								<component is="style">
									.accPass-profilePicture {
									aspect-ratio: 1;
									border: 1px solid #EEEEEE;
									border-radius: 4mm;
									object-fit: cover;
									width: 40mm;
									}
								</component>
								<img
									:src="MIX_getImagePath('profilePictures', item.userProfilePicture?.fileName, item.userProfilePicture?.fileToken)"
									alt="profile picture"
									class="accPass-profilePicture"
									height="100%"
									width="100%"/>

							</div>

						</div>

						<!--Zones-->
						<component is="style">
							.accPass-zones-container {
							border-radius: 4mm;
							background: #EEEEEE;
							display: grid;
							grid-template-rows: repeat(2, 1fr);
							grid-template-columns: repeat(3, 1fr);
							grid-gap: 4mm;
							padding: 4mm 4mm 4mm 4mm;
							margin: 0 4mm 4mm 4mm;
							}
						</component>
						<div class="accPass-zones-container">

							<!--Child Container-->
							<component is="style">
								.accPass-pass-zones-child-container {
								border: 1px solid #000055;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: space-around;
								border-radius: 4mm;
								padding: 1mm;
								}
							</component>

							<!--Child Number-->
							<component is="style">
								.accPass-zone-numbers {
								color: #000055;
								font-family: Avenir, Helvetica, Arial, sans-serif;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
								font-size: 16pt !important;
								font-weight: bold;
								}
							</component>

							<!--Child Text-->
							<component is="style">
								.accPass-zone-text {
								color: #000055;
								font-family: Avenir, Helvetica, Arial, sans-serif;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
								font-size: 8pt !important;
								text-align: center;
								}
							</component>

							<!--Active Zone-->
							<component is="style">
								.accPass-pass-zones-child-container-active {
								background: #ffdd00;
								}
							</component>

							<!--Inactive Zone-->
							<component is="style">
								.accPass-pass-zones-child-container-inactive {
								background: white;
								}
							</component>

							<!--Zone 1-->
							<div class="accPass-pass-zones-child-container"
								 :class="JSON.parse(item.userData.userDefaultZones)[0].hasAccess ? 'accPass-pass-zones-child-container-active' : 'accPass-pass-zones-child-container-inactive'">
								<app-text class="accPass-zone-numbers" color="#">1</app-text>
								<app-text class="accPass-zone-text" color="#">Grounds; General Venue</app-text>
							</div>
							<!--Zone 2-->
							<div class="accPass-pass-zones-child-container"
								 :class="JSON.parse(item.userData.userDefaultZones)[1].hasAccess ? 'accPass-pass-zones-child-container-active' : 'accPass-pass-zones-child-container-inactive'">
								<app-text class="accPass-zone-numbers" color="#">2</app-text>
								<app-text class="accPass-zone-text" color="#">Media; Media Area</app-text>
							</div>
							<!--Zone 3-->
							<div class="accPass-pass-zones-child-container"
								 :class="JSON.parse(item.userData.userDefaultZones)[2].hasAccess ? 'accPass-pass-zones-child-container-active' : 'accPass-pass-zones-child-container-inactive'">
								<app-text class="accPass-zone-numbers" color="#">3</app-text>
								<app-text class="accPass-zone-text" color="#">Broadcast; Compound/studios</app-text>
							</div>
							<!--Zone 4-->
							<div class="accPass-pass-zones-child-container"
								 :class="JSON.parse(item.userData.userDefaultZones)[3].hasAccess ? 'accPass-pass-zones-child-container-active' : 'accPass-pass-zones-child-container-inactive'">
								<app-text class="accPass-zone-numbers" color="#">4</app-text>
								<app-text class="accPass-zone-text" color="#">PMOA; Players & Match Officials</app-text>
							</div>
							<!--Zone 5-->
							<div class="accPass-pass-zones-child-container"
								 :class="JSON.parse(item.userData.userDefaultZones)[4].hasAccess ? 'accPass-pass-zones-child-container-active' : 'accPass-pass-zones-child-container-inactive'">
								<app-text class="accPass-zone-numbers" color="#">5</app-text>
								<app-text class="accPass-zone-text" color="#"></app-text>
							</div>

							<!--Logo-->
							<component is="style">
								.accPass-zones-image-container {
								display: flex;
								align-items: center;
								justify-content: center;
								}
								.accPass-zones-image {
								width: 112px;
								}
							</component>
							<div class="accPass-zones-image-container">
								<img :src="getCompanyLogo()" alt="company logo" class="accPass-zones-image"/>
							</div>

						</div>

					</div>

					<!--Pass Back Container-->
					<component is="style">
						.accPass-back-container {
						background: white;
						border: 1px solid lightgrey;
						display: grid;
						grid-template-rows: auto 1fr;
						height: 15cm;
						width: 11cm;
						}
					</component>
					<div class="accPass-back-container">

						<!--Text Style-->
						<component is="style">
							.accPass-text {
							font-family: Avenir, Helvetica, Arial, sans-serif;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							font-size: 12pt !important;
							margin-bottom: 4mm;
							}
						</component>

						<!--Terms and Conditions-->
						<component is="style">
							.accPass-terms-container {
							margin: 4mm 4mm 0 0;
							}
						</component>
						<ul class="accPass-terms-container">
							<li>
								<app-text class="accPass-text">
									This pass is to be worn only by the person for which it is intended.
								</app-text>
							</li>
							<li>
								<app-text class="accPass-text">
									This pass must be displayed at all times whilst at Sophia Gardens Cardiff.
								</app-text>
							</li>
							<li>
								<app-text class="accPass-text">
									This pass remains the property of Glamorgan County Cricket Club and can be
									retracted at any time in the event of misuse.
								</app-text>
							</li>
							<li>
								<app-text class="accPass-text">
									Please remain in your allocated zone.
								</app-text>
							</li>
							<li>
								<app-text class="accPass-text">
									If no numbers are displayed, pass holder has access to public areas only.
								</app-text>
							</li>
						</ul>

						<!--Map-->
						<component is="style">
							.accPass-map-container {
							background: #EEEEEE;
							border-radius: 4mm;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 0 4mm 4mm 4mm;
							}
						</component>
						<div class="accPass-map-container">
						</div>

					</div>

				</div>

			</div>

		</v-card>

	</div>

</template>

<script>
export default {

	name: "AccreditationPrintPass_glamorganCricket",

	props: ['eventData', 'eventImageData', 'passesData'],

	data: () => ({
		isLoading: false
	}),

	methods: {

		closeDialog() {
			const t = this

			t.$emit('closeDialog', t.$props.passesData)
		},

		getCompanyLogo() {
			try {
				// Try to load the main image
				return require('@/assets/images/company-logo-sofiagardens.svg')
			} catch (e) {
				// If it fails, load the fallback image
				return require('@/assets/images/company-logo.svg')
			}
		},

		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadUserImageFiles()
			])

			t.isLoading = false
		},

		async loadUserImageFiles() {
			const t = this

			// Get all the user profile picture IDs
			const USERS_PROFILE_PICTURE_IDS = this.$props.passesData.map(item => item.userData.userProfilePicture)

			const RESPONSE = await t.MIX_redis_getEntitiesByIds('file', USERS_PROFILE_PICTURE_IDS)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Entities by IDs: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was an error loading the user images, please try again.'
				return
			}

			const USER_FILES_DATA = RESPONSE.data

			// Loop through the user data and add the profile picture to the passesData
			t.$props.passesData.map(item => {
				item.userProfilePicture = USER_FILES_DATA.find(file => file.entityId === item.userData.userProfilePicture)
				return item
			})
		},

		printPass(printableArea) {

			const DIV_CONTENTS = document.getElementById(printableArea).innerHTML
			const WIN = window.open('', '', 'height=600, width=960')
			WIN.document.write(DIV_CONTENTS)

			setTimeout(() => {
				WIN.document.close()
				WIN.print()
			}, 1000)

		},

		calculateFontSize(text) {
			const widthOfContainer = 800
			let fontSize = widthOfContainer / text.length

			if (fontSize > 104) fontSize = 104

			return fontSize + 'px'
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	}

}
</script>

<style scoped>
@media print {

	@page {
		margin: 0;

		size: 200mm 297mm;
	}

	.noPrint {
		display: none;
	}

}
</style>
