<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructions-->
		<app-text class="mb-4">
			Complete the form to mark
			<strong>{{ selectedItem.userData.userName }}</strong>
			as leaving early.
		</app-text>

		<!--Instructions - Already left early-->
		<app-text v-if="selectedItem[`${viewType}Data`][`${viewType}EarlyFinishTime`] !== '0'" class="mb-4" color="red">
			<strong>{{ selectedItem.userData.userName }}</strong>
			was already Signed Out at
			<strong>{{ selectedItem[`${viewType}Data`][`${viewType}EarlyFinishTime`] }}</strong>,
			but you can update the details if you wish.
		</app-text>

		<!--Time Selection-->
		<time-picker2 @emitTime="handleTimeSelection($event, 'earlyFinishTime')"
					  :error="errors.earlyFinishTime"
					  :error-message="errors.earlyFinishTimeErrorMessage"
					  label="Early Finish time"
					  :time="earlyFinishTime"/>

		<!--Notes-->
		<app-form-field form-type="textArea"
						class="mt-4"
						:error="errors.earlyFinishNotes"
						:error-message="errors.earlyFinishNotesErrorMessage"
						label="Early Finish Notes"
						v-model.trim="earlyFinishNotes"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Yes-->
			<app-btn @click.native="handleYesButton"
					 color="green"
					 icon="success"
					 label="Early Finish"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventControlPanelEarlyFinish",

	props: ['selectedItem', 'viewType'],

	data: () => ({
		errors: {
			earlyFinishNotes: false,
			earlyFinishNotesErrorMessage: '',
			earlyFinishTime: false,
			earlyFinishTimeErrorMessage: '',
		},
		earlyFinishNotes: '',
		earlyFinishTime: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * Initialise the component.
		 */
		computedInit() {
			const t = this
			const VIEW_TYPE = t.$props.viewType
			const FORM_DATA = t.$props.selectedItem[`${VIEW_TYPE}Data`]
			const EARLY_FINISH_TIME = FORM_DATA[`${VIEW_TYPE}EarlyFinishTime`]
			const EARLY_FINISH_NOTES = FORM_DATA[`${VIEW_TYPE}EarlyFinishNotes`]

			t.earlyFinishTime = EARLY_FINISH_TIME === '0' ? '' : EARLY_FINISH_TIME
			t.earlyFinishNotes = EARLY_FINISH_NOTES || ''
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog box.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventControlPanelEarlyFinishDialog')
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 * Validate the time format, so if the min or max is set, the time must be within that range.
		 *
		 * @param time {string} the time to set
		 * @param min {string} the minimum time
		 * @param max {string} the maximum time
		 * @param field {string} the form field to set
		 */
		handleTimeSelection({time, min, max}, field) {
			const t = this

			// Clear any errors
			t.errors[field] = false
			t.errors[field + 'ErrorMessage'] = ''

			// Validate the time format
			if (min && time < min) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be after ' + min
				return
			}

			// Validate the time format
			if (max && time > max) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be before ' + max
				return
			}

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Handle Yes Button
		 *
		 * Set the time either to the selected time, or now if no selection is made.
		 * Emit back to the parent to update the time.
		 */
		handleYesButton() {
			const t = this

			// Only continue if the form is valid
			if (!t.validateForm()) return

			t.$emit('emitEventControlPanelEarlyFinish', {
				earlyFinishTime: t.earlyFinishTime,
				earlyFinishNotes: t.earlyFinishNotes
			})
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			// Clear any existing errors
			t.clearErrors()

			// Early Finish Time
			if (!t.earlyFinishTime) {
				t.errors.earlyFinishTime = true
				t.errors.earlyFinishTimeErrorMessage = 'Time is required.'
			}

			// Early Finish Notes
			if (!t.earlyFinishNotes) {
				t.errors.earlyFinishNotes = true
				t.errors.earlyFinishNotesErrorMessage = 'Notes are required.'
			}

			// Return true if there are no errors
			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
